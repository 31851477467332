<template>
<div>

  <list-template
      :loading="loading"
      hasAdd
      @onAdd="$router.push('./add')"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onSearch="onSearch"
      @onReset="search = null"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
      ref="table"
  >

  </list-template>
  <el-dialog class="details" :visible.sync="detailsVisible" title="教师代课详情" >
    <el-descriptions :label-style="{marginRight:'20rem'}" style="padding-bottom: 20rem" class="margin-top" :column="1" >
      <el-descriptions-item content-style="color:#000;" label="校区">{{description.school_name}}</el-descriptions-item>
      <el-descriptions-item label="代课学课">{{ description.subject }}</el-descriptions-item>
      <el-descriptions-item label="是否同科目">{{ description.same_subject ? '是' : '否' }}</el-descriptions-item>
      <el-descriptions-item label="被代课教师">{{ description.agent_teacher_name }}</el-descriptions-item>
      <el-descriptions-item label="代课课节" :span="4">
        <div>
          <el-tag v-for="item in description.course" style="margin-right: 10rem;margin-bottom: 5rem;">
            {{item}}
          </el-tag>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="实际上课教师">
        {{ description.teacher_name }}
      </el-descriptions-item>
      <el-descriptions-item label="创建人">{{ description.creator_name }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ description.created_at }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: "substituteCourse",
  computed:{
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data(){
    return{
      loading:true,
      detailsVisible:false,
      description:{},
      total:0,
      search:null,
      searchConfig:[
        {
          prop:"school_id",
          placeholder:"筛选校区",
          tag:"select",
          options:[],
          change:(e)=>{
            !this.search?this.search = {school_id: e}:this.search.school_id = e;
            this.searchConfig[1].disabled = false;
          }
        },
        {
          prop:"name",
          placeholder:"搜索被代课教师",
          // disabled:this.search?!this.search.school_id:true,
          // valueKey:"clerk_name",
          // querySearch:(str , cb)=>{
          //   this.$_axios2.get("/api/sub/substitute/teacher",{
          //     params:{
          //       school_id:this.search.school_id,
          //       name:str
          //     }
          //   }).then(res=>{
          //     let data = res.data.data;
          //     cb(data)
          //   })
          // },
          // // 教师搜索被选中
          // handleSelect:(e)=>{
          //   this.search.name = e.clerk_name
          // },
        },
      ],
      // 表格配置
      tableConfig:[
        {
          prop:"agent_teacher_name",
          label:"被代课教师"
        },
        {
          prop:"teacher_name",
          label:"实际上课教师"
        },
        {
          prop:"school_name",
          label:"校区"
        },
        {
          prop:"creator_name",
          label:"创建人"
        },
        {
          handle:true,
          label:"操作",
          width:120,
          buttons: [{ type:"view", text:"查看" }]
        },
      ],
      // 表格中的数据
      tableData:[
      ],

    }
  },
  methods:{
    ...mapMutations(["setPage"]),
    // 获取数据
    getData(){
      let {search,page} = this;
      this.loading = true;
      this.$_axios2.get("/api/sub/substitute/index",{
        params: {...search,page},

      }).then(res=>{
        let {data,status} = res.data;
        if (status === 0){
          this.total = data.total || 0;
          this.tableData = data.data;
        }

      }).finally(()=>this.loading = false)

    },
    // 搜索按钮被点击
    onSearch(val) {
      this.setPage(1);
      this.search = val
      this.getData()
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row) {
      this.$_axios2.get("/api/sub/substitute/details",{
        params:{
          id:row.id
        }
      }).then(res=>{
        if (res.data.status === 0){
          this.description = res.data.data;
          this.detailsVisible = true;
        }
      })
    }
  },
  mounted() {
    this.$store.commit("setPage",1);
    this.getData();
    for (let k in this.userInfo.school_arr){
      this.searchConfig[0].options.push({
        label:this.userInfo.school_arr[k],
        value:k
      })
    }
  },
  activated() {
    if (this.page === -1){
      this.$refs.table.reset();
    }
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.details{
  ::v-deep .el-dialog__footer{
    display: none;
  }
}
</style>
